import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Hero,
  ActionBox,
  Input,
  Textarea,
  Button,
  AlertModal,
} from "../components/common";

import PointsDownwards from "../assets/images/vectors/points-downwards.svg";
import axios from "axios";
import { ALERT_INITIAL_STATE, apiURL, getStaticSEOImage } from "../helpers";

function ContactPage({ location }) {
  const [state, setState] = useState({
    description: "",
    name: "",
    email: "",
    alert: ALERT_INITIAL_STATE,
    validation: { name: true, email: true, description: true },
  });

  const onValidate = (name, status) => {
    setState({
      ...state,
      validation: {
        ...state.validation,
        [name]: status,
      },
    });
  };

  const isSubmissionAllowed = () =>
    state.email &&
    state.name &&
    state.description &&
    state.validation.name &&
    state.validation.email &&
    state.validation.description;

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isSubmissionAllowed()) {
      const { data } = await axios.post(apiURL + "/contacts", {
        Name: state.name,
        Email: state.email,
        Description: state.description,
      });

      switch (data.code) {
        case "contact_request_sent":
          return setState({
            ...state,
            email: "",
            name: "",
            description: "",
            alert: {
              ...state.alert,
              success: true,
              error: false,
              title: "Your Message Has Been Sent",
              content: (
                <>
                  Hi {state.name}, thanks for your message. We will respond
                  within 24 hours if it’s not the weekend. Meanwhile, please
                  check out <Link to="/faq">our FAQs</Link> or browse our{" "}
                  <Link to="/products">latest products here</Link>.
                </>
              ),
            },
          });
        default:
          return setState({
            ...state,
            alert: {
              ...state.alert,
              success: false,
              error: true,
              title: "Something went wrong!",
              content: "Something went wrong! Please try again...",
            },
          });
      }
    }
  };

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Contact"
        image={getStaticSEOImage("contact-us")}
        path={location?.pathname}
      />
      <AlertModal
        show={state.alert.error || state.alert.success}
        type={state.alert?.error ? "error" : "success"}
        title={state?.alert?.title}
        content={state?.alert?.content}
        onClose={() => setState({ ...state, alert: ALERT_INITIAL_STATE })}
      />

      <section className="mt-24 mb-24">
        <Hero
          subTitle="Get in Touch"
          title={
            <>
              Tell Us Your Dreams & Stories
              <br /> We Are Listening
            </>
          }
          description="Do you have any dream project to share? Do you have any ideas, pre-sale or support questions? Would you like to have some chitchat with us? Come on, just say anything!"
        />
      </section>
      <section className="mb-40">
        <form className="max-w-4xl m-auto relative">
          <span
            style={{ bottom: "calc(100% + 15px)", left: "-65px" }}
            className="absolute md:inline-block hidden"
          >
            <img alt="point-downwards" src={PointsDownwards} />
          </span>
          <div className="mb-8">
            <Textarea
              rows={8}
              placeholder="Write your message here"
              label="Share your story, idea, problem or anything"
              minLengthErrorMessage="Please write your message in detail"
              name="description"
              onChange={(description) => setState({ ...state, description })}
              minLength={20}
              value={state.description}
              onValidate={onValidate}
            />
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
            <Input
              placeholder="John Doe"
              label="Okay, what’s your name?"
              name="name"
              minLengthErrorMessage="Too short, write your full name instead"
              value={state.name}
              onChange={(name) => setState({ ...state, name })}
              minLength={3}
              maxLength={32}
              onValidate={onValidate}
            />
            <Input
              placeholder="email@example.com"
              label={
                <>
                  And your email? <small>(only used to reply you back)</small>
                </>
              }
              name="name"
              minLengthErrorMessage="Enter a valid email or try another"
              patternErrorMessage="Enter a valid email or try another"
              onChange={(email) => setState({ ...state, email })}
              value={state.email}
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              onValidate={onValidate}
            />
          </div>
          <div className="flex flex-col items-center justify-center mt-16">
            <Button
              disabled={!isSubmissionAllowed()}
              hasMoustache
              icon="vg-mail"
              text="Send Message"
              onClick={onSubmit}
            />
            <div className="text-center mt-4 font-medium text-ui-gray-plus-3 text-xs leading-4">
              We read and reply your message between
              <br />
              10 AM - 7 PM, GMT +6
            </div>
          </div>
        </form>
      </section>
      <section className="mb-24">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
          <ActionBox icon="vg-help-circle">
            Some Common
            <br />
            <Link className="text-brand-primary underline" to="/faq">
              Questions & Answers
            </Link>
          </ActionBox>
          <ActionBox icon="vg-message">
            Chat With
            <br />
            <a
              target="__blank"
              rel="noopener noreferrer"
              className="text-brand-primary underline"
              href="https://m.me/vackground"
            >
              Messenger
            </a>{" "}
            or{" "}
            <a
              target="__blank"
              rel="noopener noreferrer"
              className="text-brand-primary underline"
              href="https://twitter.com/vackground"
            >
              Twitter
            </a>
          </ActionBox>
          <ActionBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-mail"
          >
            Send Email at
            <br />
            <a
              className="text-brand-primary underline"
              href="mailto:hi@vackground.com"
            >
              hi@vackground.com
            </a>
          </ActionBox>
        </div>
      </section>
    </Layout>
  );
}

ContactPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ContactPage;
